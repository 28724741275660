/**
 * TODO: aggiungere loader all'interno delle select2 quando sono disabled in attesa di risposta dal server geonames
 */

var geonames = {

    language: {
        'noResults': function () {
            return 'Nessun elemento trovato';
        }
    },

    fill: function ($node, key, value, empty, prefix, callback) {
        var data = geonames.constructor.map[key];
        var json = $node.attr('data-json');
        var tags = $node.attr('data-tags');
        var found = false;

        $node.html("");
        if (empty)
        // aggiungo la option vuota
            $node.append('<option value="">&nbsp;</option>');

        $.each(data, function () {
            var selected, id, label, label_base, val, iso2, iso3, admin1, admin2, region, region_label, region_label_base;

            if (prefix) {
                var phone = this.phone.replace("+", "").replace("-", "");
                selected = value == phone ? "selected" : "";
                id = phone;
                if (typeof this.an !== 'undefined' && this.an != null) {
                    label = this.an + ' (+' + phone + ')';
                } else {
                    label = this.alternate_name + ' (+' + phone + ')';
                }
            } else {
                if (typeof this.id !== 'undefined' && this.id != null) {
                    selected = value == this.id ? "selected" : "";
                    if (value == this.id)
                        found = true;
                    id = this.id;
                } else {
                    selected = value == this.geonameid ? "selected" : "";
                    if (value == this.geonameid)
                        found = true;
                    id = this.geonameid;
                }

                if (typeof this.an !== 'undefined' && this.an != null) {
                    label = this.an;
                } else {
                    label = this.alternate_name;
                }
            }

            if (typeof this.anb !== 'undefined' && this.anb != null) {
                label_base = this.anb;
            } else {
                label_base = this.alternate_name_base;
            }

            if (typeof this.i2 !== 'undefined' && this.i2 != null) {
                iso2 = this.i2;
            } else {
                iso2 = this.iso_alpha2;
            }

            if (typeof this.i3 !== 'undefined' && this.i3 != null) {
                iso3 = this.i3;
            } else {
                iso3 = this.iso_alpha3;
            }

            if (typeof this.a1 !== 'undefined' && this.a1 != null) {
                admin1 = this.a1;
            } else {
                admin1 = this.admin1;
            }

            if (typeof this.a2 !== 'undefined' && this.a2 != null) {
                admin2 = this.a2;
            } else {
                admin2 = this.admin2;
            }

            if (typeof this.region !== 'undefined' && this.region != null) {
                if (typeof this.region.id !== 'undefined' && this.region.id != null) {
                    region = this.region.id;
                } else {
                    region = this.region.geonameid;
                }

                if (typeof this.region.an !== 'undefined' && this.region.an != null) {
                    region_label = this.region.an;
                } else {
                    region_label = this.region.alternate_name;
                }

                if (typeof this.region.anb !== 'undefined' && this.region.anb != null) {
                    region_label_base = this.region.anb;
                } else {
                    region_label_base = this.region.alternate_name_base;
                }
            }

            if (json) {
                val = JSON.stringify({
                    geonameid: id,
                    alternate_name: label,
                    alternate_name_base: label_base,
                    iso2: iso2,
                    iso3: iso3,
                    admin1: admin1,
                    admin2: admin2,
                    region: region,
                    region_alternate_name: region_label,
                    region_alternate_name_base: region_label_base,
                    latitude: this.latitude,
                    longitude: this.longitude,
                });
            } else {
                val = "" + id;
            }
            $node.append('<option value=\'' + val.replace(/'/g, "&apos;").replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;") + '\' data-continent="' + this.continent + '" data-countrycode="' + this.iso_alpha2 + '" ' + selected + '>' + label + '</option>');
            if (selected)
            // ho selezionato qualcosa quindi scateno il trigger del click
                $node.trigger('change');
        });

        if ($.trim(value) !== '' && value !== '##-##-##' && found == false && (tags == '1' || tags == 'true')) {
            $node.append('<option value=\'' + value.replace(/'/g, "&apos;").replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;") + '\' data-continent="" data-countrycode="" selected>' + value + '</option>').trigger('change');
        }

        callback();
    },

    load: function ($node, url, prefix, callback) {
        var preload = $node.attr('data-ajax');
        preload = typeof preload == "undefined" || preload == "true" || preload == "1";
        var value = $node.attr('data-value');
        var lang = $node.attr('data-lang');
        var empty = parseInt($node.attr('data-empty')) || 1;
        var type = $node.attr('data-geoname');
        var key, country, province;

        if ($.trim(value) == "")
            value = "##-##-##"; // valore inesistente -> fix per value = "" -> conflitto!

        switch (type) {
            case 'prefix':
            case 'country':
                key = 'countries';
                break;
            case 'province':
                country = $node.attr('data-country');
                key = 'countries_' + country;
                break;
            case 'city':
                country = $node.attr('data-country');
                province = $node.attr('data-province');
                key = 'countries_' + country + "_" + province;
                break;
        }

        prefix = prefix || false;

        if (!preload) {
            // TODO: select2 ajax
        } else {
            if (geonames.constructor.map[key]) {
                geonames.fill($node, key, value, empty, prefix, callback);
            } else {
                // faccio una chiamata per recuperare le entries
                $node.attr('disabled', 'disabled');
                $.get(url, {lang: lang})
                    .done(function (data) {
                        geonames.constructor.map[key] = data;

                        geonames.fill($node, key, value, empty, prefix, callback);

                        $node.removeAttr('disabled');
                    })
                    .fail(function (data) {
                        app.error("", app.parseAjaxError(data));
                        $node.removeAttr('disabled');
                    });
            }
        }
    },

    init: function () {
        // trasformo i data-geoname in select2 precaricando o meno i dati
        $('[data-geoname=country]').each(function () {
            var tags = $(this).attr('data-tags');
            var placeholder = $(this).attr('data-placeholder');
            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");

            $(this).select2({
                // minimumResultsForSearch: 8,
                tags: tags,
                placeholder: placeholder,
                language: geonames.language
            });
        });

        $('[data-geoname=province]').each(function () {
            var tags = $(this).attr('data-tags');
            var placeholder = $(this).attr('data-placeholder');
            var isnull = $(this).attr('data-null');

            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");
            isnull = typeof isnull != "undefined" && (isnull == "true" || isnull == "1");

            $(this).select2({
                // minimumResultsForSearch: 8,
                tags: tags,
                placeholder: placeholder,
                allowClear: isnull,
                language: geonames.language,
                insertTag: function (data, tag) {
                    tag.isTag = true;
                    data.push(tag);
                },
                templateResult: function (option) {
                    if (option.isTag) {
                        return $('<div class="add-new"><i class="fa fa-plus-circle"></i> ' + option.text + '</div>')
                    } else {
                        return option.text
                    }
                }
            });
        });

        $('[data-geoname=city]').each(function () {
            var tags = $(this).attr('data-tags');
            var placeholder = $(this).attr('data-placeholder');
            var value = $(this).attr('data-value');
            var isnull = $(this).attr('data-null');

            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");
            isnull = typeof isnull != "undefined" && (isnull == "true" || isnull == "1");

            $(this).select2({
                // minimumResultsForSearch: 8,
                tags: tags,
                placeholder: placeholder,
                allowClear: isnull,
                language: geonames.language,
                insertTag: function (data, tag) {
                    tag.isTag = true;
                    data.push(tag);
                },
                templateResult: function (option) {
                    if (option.isTag) {
                        return $('<div class="add-new"><i class="fa fa-plus-circle"></i> ' + option.text + '</div>')
                    } else {
                        return option.text
                    }
                }
            });

            if (tags) {
                var newOption = new Option(value, value, false, false);
                $(this).append(newOption).trigger('change');
            }
        });

        $('[data-geoname=prefix]').each(function () {
            var tags = $(this).attr('data-tags');
            var placeholder = $(this).attr('data-placeholder');
            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");

            $(this).select2({
                // minimumResultsForSearch: 8,
                tags: tags,
                placeholder: placeholder,
                language: geonames.language
            });
        });

        // eseguo il bind degli eventi
        geonames.bindPrefixesEvents();
        geonames.bindCountryEvents();
        geonames.bindProvinceEvents();
        geonames.bindCityEvents();

        var fnz = function ($node, $prefix) {
            // abilito country, province city e prefix
            $('[data-geoname=country]').removeAttr('disabled');
            $('[data-geoname=province]').removeAttr('disabled');
            $('[data-geoname=city]').removeAttr('disabled');
            $('[data-geoname=prefix]').removeAttr('disabled');

            if (typeof $prefix != 'undefined') {
                $prefix.trigger('geonames.prefixes.load');
                $prefix.trigger('geonames.prefix.load');
            }

            $('[data-geoname=country]').each(function () {
                var $country = $(this);

                // disabilito country, province e city
                $('[data-geoname=country]').attr('disabled', 'disabled');
                $('[data-geoname=province]').attr('disabled', 'disabled');
                $('[data-geoname=city]').attr('disabled', 'disabled');

                // carico i dati
                geonames.load($(this), app.siteUrl + "/countries", false, function () {
                    // abilito country, province e city
                    $('[data-geoname=country]').removeAttr('disabled');
                    $('[data-geoname=province]').removeAttr('disabled');
                    $('[data-geoname=city]').removeAttr('disabled');

                    // trigger evento load
                    $country.trigger('geonames.countries.load');
                    $country.trigger('geonames.country.load');
                });
            });
        };

        if ($('[data-geoname=prefix]').length > 0) {
            $('[data-geoname=prefix]').each(function () {
                var $prefix = $(this);

                // disabilito country, province city e prefix
                $('[data-geoname=country]').attr('disabled', 'disabled');
                $('[data-geoname=province]').attr('disabled', 'disabled');
                $('[data-geoname=city]').attr('disabled', 'disabled');
                $('[data-geoname=prefix]').attr('disabled', 'disabled');

                // carico i dati
                geonames.load($(this), app.siteUrl + "/countries", true, function () {
                    fnz($prefix);
                });
            });
        } else {
            fnz();
        }
    },

    bindCountryEvents: function () {
        $('[data-geoname=country]').on('change', function () {
            var group = $(this).attr('data-group');
            var country = $(this).val();
            var $provinces, $cities, $prefixes;
            var json = $(this).attr('data-json');

            if (typeof group != "undefined") {
                $provinces = $('[data-geoname=province][data-group=' + group + ']');
                $cities = $('[data-geoname=city][data-group=' + group + ']:not([data-tags="true"]):not([data-tags="1"])');
                $prefixes = $('[data-geoname=prefix][data-group=' + group + ']');
            } else {
                $provinces = $('[data-geoname=province]');
                $cities = $('[data-geoname=city]:not([data-tags="true"]):not([data-tags="1"])');
                $prefixes = $('[data-geoname=prefix]');
            }

            geonames.countries = undefined;

            // devo caricare le province
            if ($provinces.length > 0 && $.trim(country) != "") {
                if (json) {
                    country = JSON.parse(country).geonameid;
                    if (typeof country === 'undefined') {
                        country = JSON.parse(country).id;
                    }
                }

                $provinces.html("").attr('data-country', country).trigger('change');
                $cities.html("").attr('data-country', country).trigger('change');

                // ho dei selettori province per il mio gruppo quindi ne carico il contenuto in base alla country selezionata
                $provinces.each(function () {
                    var $province = $(this);

                    // disabilito province e city
                    $('[data-geoname=province]').attr('disabled', 'disabled');
                    $('[data-geoname=city]').attr('disabled', 'disabled');

                    // carico i dati
                    geonames.load($(this), app.siteUrl + "/countries/" + country + "/provinces", false, function () {
                        // abilito province e city
                        $('[data-geoname=province]').removeAttr('disabled');
                        $('[data-geoname=city]').removeAttr('disabled');

                        $province.trigger('geonames.provinces.load');
                        $province.trigger('geonames.province.load');
                    });
                });
            }

            // devo selezionare il prefisso corretto
            if ($prefixes.length > 0 && $.trim(country) != "") {
                var code = $(this).find('option:selected').attr('data-countrycode');
                var value = $prefixes.find('option[data-countrycode=' + code + ']').attr('value');
                $prefixes.val(value).trigger('change');
            }
        });
    },

    bindProvinceEvents: function () {
        $('[data-geoname=province]').on('change', function () {
            var group = $(this).attr('data-group');
            var province = $(this).val();
            var country = $(this).attr('data-country');
            var $cities;
            var json = $(this).attr('data-json');

            if (typeof group != "undefined") {
                $cities = $('[data-geoname=city][data-group=' + group + ']');
            } else {
                $cities = $('[data-geoname=city]').trigger('change');
            }

            geonames.countries = undefined;

            // devo caricare le province
            if ($cities.length > 0 && $.trim(province) != "") {
                if (json) {
                    try {
                        province = JSON.parse(province).geonameid;
                        if (typeof province === 'undefined') {
                            province = JSON.parse(province).id;
                        }
                    } catch (e) {}
                }

                $cities.html("").attr('data-province', province).attr('data-country', country);

                // ho dei selettori province per il mio gruppo quindi ne carico il contenuto in base alla country selezionata
                $cities.each(function () {
                    var $city = $(this);

                    // disabilito city
                    $('[data-geoname=city]').attr('disabled', 'disabled');

                    // carico i dati
                    geonames.load($(this), app.siteUrl + "/countries/" + country + "/provinces/" + province + "/cities", false, function () {
                        // abilito city
                        $('[data-geoname=city]').removeAttr('disabled');

                        $city.trigger('geonames.cities.load');
                        $city.trigger('geonames.city.load');
                    });
                });
            }
        });
    },

    bindPrefixesEvents: function () {},

    bindCityEvents: function () {}

};

geonames.constructor.map = {};